/* eslint-disable no-undef */
import React, { useState } from "react"
import data from "@components/pageChannels/pageWhatsapp/data"
import Footer from "@components/sharedComponents/headerAndFooter/footer/footer"
import FooterMobile from "@components/sharedComponents/headerAndFooter/footer/footerMobile"
import Header from "@components/sharedComponents/headerAndFooter/header/header"
import Start from "@components/pageChannels/pageWhatsapp/intro/intro"
import Customers from "@components/sharedComponents/customersLogos/customersLogos"
import BannerCTA from "@components/sharedComponents/bannerCTA/bannerCTA"
import ArticleBlog from "@components/sharedComponents/articleBlog/articleBlog"
import AccordionLeft from "@components/sharedComponents/accordionLeft/accordionLeft"
import Testimonies from "@components/sharedComponents/testimonies/testimonies"
import BannerCookies from "@components/sharedComponents/bannerCookies/bannerCookies"
import Experience from "@components/pageChannels/pageWhatsapp/experience/experience"
import { TitleUnderlineLeftOne, TitleUnderlineLeftTwo } from "@components/pageChannels/pageWhatsapp/titleUnderline/titleUnderlineAccordionLeft"
import TitleUnderlineTestimony from "@components/pageChannels/pageWhatsapp/titleUnderline/titleUnderlineTestimony"
import { TitleUnderlineNavbarVerticalOne, TitleUnderlineNavbarVerticalTwo } from "@components/pageChannels/pageWhatsapp/titleUnderline/titleUnderlineNavbarVertical"
import finanzas from "@components/sharedComponents/testimonies/images/iconos/finanzas.svg"
import concesionarias from "@components/sharedComponents/testimonies/images/iconos/concesionarias.svg"
import salud from "@components/sharedComponents/testimonies/images/iconos/salud.svg"
import logistica from "@components/sharedComponents/testimonies/images/iconos/logistica.svg"
import gobierno from "@components/sharedComponents/testimonies/images/iconos/gobierno.svg"
import Industries from "@components/pageChannels/pageWhatsapp/industries/industries"
import BannerVideo from "@components/sharedComponents/bannerVideo/bannerVideo"
import NavbarVertical from "@components/sharedComponents/navbarVertical/navbarVertical"
import ImageVerticalOne from "@components/pageChannels/pageWhatsapp/images/imagesNavbarVertical/imageOne.webp"
import ImageVerticalFour from "@components/pageChannels/pageWhatsapp/images/imagesNavbarVertical/imageFour.webp"

const TemplatePageWhatsapp = ({ location }) => {

  //Video youtube
  const [video, setVideo] = useState(false)
  const openVideo = value => {
    setVideo(value)
  }

  //velocidad de entrada de textos y animaciones para desktop y mobile
  const windowsWidth = true

  const titleAccordionLeft = <p> Con Beex, <TitleUnderlineLeftOne underline="mejora" /> <TitleUnderlineLeftTwo underline="tus operaciones" />  de mensajería y adáptate a las necesidades de tus clientes </p>
  const titleTestimony = <p> Ayudamos a  <TitleUnderlineTestimony underline="diferentes" />  <TitleUnderlineTestimony underline="sectores" /> a fortalecer la relación con sus clientes por WhatsApp
  </p>
  const titleNavbarVertical = <p> WhatsApp Business API potencia y <TitleUnderlineNavbarVerticalOne underline="automatiza los" /> <TitleUnderlineNavbarVerticalTwo underline="casos de uso" />   más implementados del mercado </p>


  const iconsSilder = i => {
    switch (i) {
      case 0:
        return finanzas

      case 1:
        return concesionarias

      case 2:
        return salud
      case 3:
        return logistica
      case 4:
        return gobierno
      default:
        return ""
    }
  }
  const titleSilder = i => {
    switch (i) {
      case 0:
        return "Finanzas"
      case 1:
        return "Concesionarias"
      case 2:
        return "Salud"
      case 3:
        return "Logística"
      case 4:
        return "Gobierno"
      default:
        return "Conoce otros casos de éxito"
    }
  }

const objOne =     {
  tab: "1",
  title: "Envío de ofertas y promociones",
  text: (
    <p>
      Tanto para fechas clave de descuentos o para nuevos lanzamientos de productos, fideliza a tus clientes por WhatsApp enviando mensajes únicos y personalizados de forma masiva. Todo esto en simples pasos, gracias a las integraciones nativas de nuestra solución.
    </p>
  ),
  image: ImageVerticalOne,
}
const objTwo =   {
  tab: "1",
  title: "Envío de ofertas y promociones",
  text: (
    <p>
      Tanto para fechas clave de descuentos o para nuevos lanzamientos de productos, fideliza a tus clientes por WhatsApp enviando mensajes únicos y personalizados de forma masiva. Todo esto en simples pasos, gracias a las integraciones nativas de nuestra solución.
    </p>
  ),
  image: ImageVerticalFour,
}


  return (
    <div className="fnd">
      <section>
        <BannerCookies />
        {/* banner webinar */}
        {/* <Cta />
        <div className="banner-invisible-ebook" />        */}
          <Header
            path="/productos/"
            location={location}
            windowsWidth={windowsWidth}
          />
        {video ?
          <>
            <button
              className="button-video-youtube-beex"
              onClick={() => {
                openVideo(false)
              }}
            >
              X
            </button>
            <div className="container-video-youtube-beex">
              <div className="container-video-youtube-beex-background"></div>
              <div className="container-video-youtube-beex-iframe">
                <iframe
                  width="100%"
                  height="100%"
                  src="https://www.youtube.com/embed/Biyz_7gipWQ?autoplay=1"
                  title="Beex Teams"
                  frameBorder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                  allowFullScreen
                ></iframe>
              </div>
            </div>
          </> : ""}


        <div className="container">
          <Start data={data.start} location={location} />
          <Customers data1={data.customersDesktop} data2={data.customersTablet} title="¡Empresas con nuevas experiencias conversacionales gracias a la API de WhatsApp Business!" />
          <AccordionLeft data={data.accordionLeft} title={titleAccordionLeft} description={""} />
          <Testimonies data={data.testimonies} location={location} title={titleTestimony} iconsSilder={iconsSilder} titleSilder={titleSilder} />
          <BannerCTA
            title="¿Quieres saber más sobre la API de WhatsApp Business?"
            text="Nuestro equipo comercial está dispuesto a ayudarte con tu caso de uso en específico."
            link="/hablemos/"
            textButton="Contacta con nosotros"
          />
          <NavbarVertical dataOne={data.navbarVerticalRight} dataTwo={data.navbarVerticalLeft}
            dataSilder={data.navbarVerticalSilder}
            objOne={objOne}
            objTwo={objTwo}
            title={titleNavbarVertical}
            description={<p className="container-navbar-vertical-description">Conoce como la API de WhatsApp mejora las principales métricas de satisfacción, conversión y ventas. Automatiza con IA tus principales procesos de comunicación con Beex y WhatsApp</p>} />

          <Experience data={data.modules} location={location} />
          <BannerCTA
            title="Automatiza tu comunicación por WhatsApp ahora mismo"
            text="Agenda una reunión con nuestros expertos y conoce de primera mano cómo aprovechar la API de WhatsApp."
            link="/hablemos/"
            textButton="Agendar una reunión"
          />
          <Industries data={data.industries} />
          <BannerVideo openVideo={openVideo} data={data.bannerVideo} />
          <ArticleBlog data={data.articles} home={true} />
          <Footer data={data.footer} location={location} />
          <FooterMobile data={data.footer} location={location} />
        </div>
      </section>
    </div>
  )
}

export default TemplatePageWhatsapp
